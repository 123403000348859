import axios from "axios";

import {
  FETCH_SWING_USERS_REQUEST,
  FETCH_SWING_USERS_SUCCESS,
  FETCH_SWING_USERS_FAILURE,
  FETCH_SWING_TRIPS_REQUEST,
  FETCH_SWING_TRIPS_SUCCESS,
  FETCH_SWING_TRIPS_FAILURE,
  STATE_CLEAN,
} from "./SwingTypes";

import {
  fetchSwingUsersURL,
  fetchSwingTripsURL,
  swingUserURL,
  swingVehicleURL,
  swingQuotesURL,
  swingUpdateQuotesURL,
} from "../../urls";

// Fetch Swing Users
export const fetchSwingUsersRequest = () => {
  return {
    type: FETCH_SWING_USERS_REQUEST,
  };
};

export const fetchSwingUsersSuccess = (SwingUsers) => {
  return {
    type: FETCH_SWING_USERS_SUCCESS,
    payload: SwingUsers,
  };
};

export const fetchSwingUsersFailure = (error) => {
  return {
    type: FETCH_SWING_USERS_FAILURE,
    payload: error,
  };
};

export const fetchSwingUsers = () => {
  return (dispatch) => {
    dispatch(fetchSwingUsersRequest());
    axios
      .get(fetchSwingUsersURL)
      .then((response) => {
        const SwingUsers = response.data.data.users;
        // console.log("Swing Users- ", response.data.data);
        dispatch(fetchSwingUsersSuccess(SwingUsers));
      })
      .catch((error) => {
        dispatch(fetchSwingUsersFailure(error.message));
      });
  };
};

// Fetch Swing Trips
export const fetchSwingTripsRequest = () => {
  return {
    type: FETCH_SWING_TRIPS_REQUEST,
  };
};

export const fetchSwingTripsSuccess = (SwingTrips) => {
  return {
    type: FETCH_SWING_TRIPS_SUCCESS,
    payload: SwingTrips,
  };
};

export const fetchSwingTripsFailure = (error) => {
  return {
    type: FETCH_SWING_TRIPS_FAILURE,
    payload: error,
  };
};

export const fetchSwingTrips = () => {
  return (dispatch) => {
    dispatch(fetchSwingTripsRequest());
    axios
      .get(fetchSwingTripsURL)
      .then((response) => {
        const SwingTrips = response.data.data.trips;
        console.log("Swing Trips- ", response.data.data);
        dispatch(fetchSwingTripsSuccess(SwingTrips));
      })
      .catch((error) => {
        dispatch(fetchSwingTripsFailure(error.message));
      });
  };
};

export const updateSwingUser = (userData) => {
  return (dispatch) => {
    axios
      .put(swingUserURL(userData.id), userData)
      .then((response) => {
        // const SwingTrips = response.data.data.trips;
        console.log("Swing User Update-- ", response.data);
        dispatch(fetchSwingUsers())
        alert("User updated successfully!");
      })
      .catch((error) => {
        alert("User update failed", error);
      });
  };
};

export const updateSwingVehicle = (vehicleData) => {
  return (dispatch) => {
    dispatch(fetchSwingUsers());
    axios
      .put(swingVehicleURL(vehicleData.id), vehicleData)
      .then((response) => {
        // const SwingTrips = response.data.data.trips;
        console.log("Swing User Update-- ", response.data);
        alert("Vehicle updated successfully!");
      })
      .catch((error) => {
        alert("Vehicle update failed!", error);
      });
  };
};

export const getQuotes = (tripId, success, fail) => {
  return (dispatch) => {
    let url = swingQuotesURL(tripId);
    axios
      .get(url)
      .then((response) => {
        console.log('Quotes', response);
        success(response.data.data.quotes);
      })
      .catch((error) => {
        console.log('error on getting Quotes', error);
        fail();
      });
  };
};

export const updateQuote = (data, success, fail) => {
  return (dispatch) => {
    let url = swingUpdateQuotesURL(data.quoteId);
    let body = {
      quotedAmount: data.quoteAmount,
      isAccepted: 1,
    };
    axios
      .put(url, body)
      .then((response) => {
        console.log('Quotes', response);
        success();
      })
      .catch((error) => {
        console.log('error on updating Quotes', error);
        fail();
      });
  };
};

//
export const stateClean = () => {
  return {
    type: STATE_CLEAN,
  };
};
